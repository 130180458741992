import React from 'react';
import { connect } from 'react-redux';
import EnsoSteps from '../components/enso-steps';
import Layout from '../components/layout';
import '../styles/pages/summary.css';

const JobSummaryPage = ({ email }) => (
    <Layout requireLogin className="job-summary-page container">
        <div className="stepper">
            <EnsoSteps step={2} />
        </div>
        <div className="content">
            <div className="row mb-5">
                <h1>Migration started</h1>
            </div>
            <p className="row mb-5">
                You will receive an email from us with the results of your
                migration at {email}.
            </p>
        </div>
    </Layout>
);

export default connect(
    ({ enso: { registration: { email } } }) => ({ email }),
    null,
)(JobSummaryPage);
